<template>
  <div class="houseRent">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div
        class="list"
        v-for="(item, index) in listData"
        :key="index"
        @click="handelClickDetail(item)"
      >
        <div class="section-t">
          <div class="item-l">{{ item.roomCode }}</div>
          <div class="item-r">{{ item.storeName }}</div>
        </div>
        <div class="section-b">
          <div class="item-t">房型名称：{{ item.roomTypeName }}</div>
          <div :class="['item-b', { active: item.roomStatus == '已出租' }]">
            {{ item.roomStatus }}
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { getHouseRentList } from "./api.js";
export default {
  name: "houseRent",
  components: {},
  props: {},
  data() {
    return {
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onLoad() {
      this.$axios
        .get(getHouseRentList, {
          params: this.requestData,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.requestData.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.spaceId);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.spaceId) || this.listData.push(item);
                });
              }
              this.requestData.curPage++;
            }
          }
        });
    },
    //跳转详情
    handelClickDetail(item) {
      this.$router.push({
        name: "houseRentDetail",
        query: {
          spaceId: item.spaceId,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.houseRent {
  width: 100%;
  min-height: 100vh;

  box-sizing: border-box;
  padding: 40px 30px 0 30px;
  background-color: #f0f2f5;
  padding-bottom: calc(60px + constant(safe-area-inset-bottom));
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
  .list {
    min-height: 208px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #8a95a6;
    overflow: hidden;
    &:not(:last-child) {
      margin-bottom: 34px;
    }
    .section-t {
      padding: 14px 24px 16px 24px;
      width: 100%;
      min-height: 70px;
      background: #8a95a6;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      .item-l {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
      }
      .item-r {
        flex-shrink: 0;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
      }
    }
    .section-b {
      padding: 24px;
      box-sizing: border-box;
      .item-t {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        margin-bottom: 12px;
      }
      .item-b {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #558ada;
        line-height: 40px;
        &.active {
          color: #8a95a6;
        }
      }
    }
  }
}
</style>
